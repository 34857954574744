@import "npm:source-sans/source-sans-3VF.css";
@import "npm:source-serif/source-serif-subhead.css";

@import "@materializecss/materialize/sass/materialize.scss";
@import "_color.scss";

/* Custom Stylesheet */
html {
  font-family: "Source Sans 3 VF", sans-serif;
  font-weight: 300;
}

body {
  max-width: 1900px;
  margin: 0 auto;
  background-color: color("grey", "lighten-5");
}

strong {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
}

.navbar-fixed {
  nav {
    right: auto;

    background-color: rgba(255, 255, 255, 0.95);
    color: var(--md-sys-color-primary);
    border-top: 15px solid color("cntx-red", "base");
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.16),
      0 5px 10px -5px rgba(0, 0, 0, 0.12); // nur unten
    height: 79px;
    transition: all 0.4s ease;
    max-width: 1900px;

    img.logo,
    svg.logo {
      height: 42px;
      width: auto;
      vertical-align: top;
      transition: all 0.4s ease;
      padding-top: 0;
      margin-top: 10px;

      path.fil0 {
        fill: color("grey", "darken-3");
      }
    }

    a {
      color: color("grey", "darken-3");

      &.brand-logo.right {
        transition: all 0.4s ease;
        padding-top: 0;
      }
    }

    ul {
      &.left {
        padding-top: 0;
        transition: all 0.4s ease;
      }

      a {
        font-size: 1rem;
        font-weight: 300;
        transition: all 0.4s ease;
        color: color("grey", "darken-3");
      }

      li {
        padding: 0 10px;

        &.small a {
          font-size: 80%;
        }

        &.small .material-icons {
          transform: scale(0.5);
          color: color("grey", "lighten-1");
        }

        a {
          padding: 0 5px;
          display: inline;

          &:hover,
          &.active {
            background: inherit !important;
            border-bottom: 3px solid color("cntx-red", "base");
          }

          color: var(--md-sys-color-primary) !important;
        }
      }
    }
  }
}

.sidenav {
  li {
    a {
      color: var(--md-sys-color-primary) !important;
    }
  }
}

p {
  line-height: 2rem;
}

.button-collapse {
  color: #26a69a;
}

#gmap {
  height: 500px;
}

.gm-style,
.gm-style div {
  font-family: inherit !important;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--md-sys-color-primary);
}

h1 {
  font-style: italic;
  font-family: "Source Serif";
}

.flow-text {
  line-height: 150%;
}

$section-padding: 3rem;

.section {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
}

#competences .material-icons {
  color: var(--md-sys-color-primary) !important;
}

.contact {
  $size: 175px;
  $padding: 5px;

  .card-panel {
    padding: $padding;
    height: $size + 2 * $padding;
    width: $size + 2 * $padding;
    margin: 0 auto;
    border-radius: 50%;

    img {
      width: $size;
      height: $size;
      border-radius: 50%;
    }
  }

  .details {
    width: $size;
    padding: 0;
    padding-left: 1rem;
    margin: 2rem auto;

    p {
      // height: 36px;
      font-weight: 300;
      text-align: left;

      &.name {
        font-weight: 500;
        margin-top: 0;
      }

      .material-icons {
        color: color("cntx-red", "base");
        font-size: 1.5rem;
        //margin-right: -13px;
        line-height: inherit;
        margin-top: 1px;
      }

      span {
        //padding-top: 12px;
        font-size: 1.1rem;
        display: table-cell;
      }
    }
  }
}

.section.sab {
  .icon-block img {
    width: 100%;
    padding-top: 25px;
    max-width: 200px;
  }

  .flow-text {
    font-size: 1.2rem;
    line-height: 125%;
  }
}

.parallax-container {
  min-height: 600px;
  line-height: 0;
  height: auto;

  .section {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    position: absolute;
    top: 10%;

    h1 span,
    h5 span {
      background: rgba(255, 255, 255, 0.75);
      padding: 2px 5px;
      line-height: 150%;
    }

    #s404 & h1 {
      font-size: 8.4rem;
    }

    #contact & {
      top: 10px;
    }

    #home &.bg1 {
      min-height: 300px;
    }
  }

  .parallax {
    #contact & {
      background-color: white;
    }

    img {
      transform: translate3d(-50%, 200px, 0px);
      display: block;
      filter: drop-shadow(5px 5px 5px #00000080);

      #contact & {
        transform: translate3d(-50%, 0, 0);
      }
    }
  }
}

.icon-block {
  padding: 0 15px;

  .material-icons {
    font-size: inherit;
  }
}

.slider {
  .slides {
    background-color: white;

    li .caption {
      color: color("cntx-red", "darken-2");

      img {
        border: 5px #fff solid;
        border-radius: 2px;
        max-width: 240px;
        max-height: 240px;

        &.ppl {
          border-radius: 50%;
          width: 100px;
          height: 100px;
        }
      }

      h5 {
        font-family: "Source Serif", serif;
        font-size: 2.25rem;
        font-style: italic;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
        quotes: "\201C" "\201D" "\2018" "\2019";
        color: inherit;

        &:before {
          content: open-quote;
          font-size: 5rem;
          line-height: 0.1em;
          vertical-align: -0.4em;
          opacity: 0.75;
          margin-right: 0.1em;
          margin-left: -0.55em;
        }

        &:after {
          content: close-quote;
          opacity: 0.75;
        }
      }

      p.name {
        color: inherit;
        line-height: 135%;
        margin: 0;
      }
    }
  }

  .indicators {
    bottom: 50px;

    .indicator-item.active {
      background-color: color("cntx-red", "base");
    }
  }
}



#projects {
  ul {
    list-style-type: '| ';
    list-style-position: outside;
    padding-left: 1em;

    li {
      &::marker {
        font-weight: bold;
        color: #999 !important;
      }

      margin-top: 1ex;
    }
  }

  // carousel
  .carousel {
    .carousel-item {
      img {
        max-height: 480px;
        max-width: 100%;
        width: auto;
        height: auto;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
      }
    }

    .indicator-item {
      background-color: #000;
      opacity: 0.25;

      &.active {
        opacity: 0.5;
      }
    }
  }
}

img.blur {
  filter: blur(5px);
  opacity: 0.25;
}

footer.page-footer {
  margin-top: 0;
  font-size: 90%;
  background-color: color("grey", "lighten-4");
  color: var(--md-sys-color-on-primary);
  border: none;

  .disclaimer {
    color: color("grey", "darken-3");

    h5,
    p {
      font-size: 95%;
      color: inherit;
    }

    h5 {
      font-weight: 400;
      margin-top: 21px;
    }
  }

  div.remarks {
    color: color("grey", "darken-2");
    background-color: color("grey", "lighten-3");

    div.esf {
      transition: all 1s ease;
      overflow: hidden;
      max-height: 500px;

      &.zero {
        max-height: 0;
      }

      h5 {
        color: inherit;
      }
    }
  }

  img {
    vertical-align: middle;
    margin-top: 20px;

    &.esf {
      height: 46px;
      margin-bottom: -20px;

      &:hover {
        cursor: pointer;
      }

      &:not(:first-of-type) {
        margin-left: 50px;
      }
    }

    &.oes {
      height: 75px;
      margin-left: 40px;
      margin-bottom: 10px;
    }
  }

  .footer-copyright {
    background-color: #c31525;
    height: 48px;
    line-height: 48px;

    img.esf {
      background-color: white;
      border: 5px solid white;
      border-radius: 2px;
    }
  }

  p {
    line-height: 135%;
  }
}

.no-mar-bot {
  margin-bottom: 0;
}

.info_content {
  h5 {
    margin-bottom: 0;
  }

  a {
    color: inherit !important;
  }

  .row {
    margin-bottom: 0;

    p {
      white-space: nowrap;
      font-size: 85%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

/* Besonderheiten nach Bildschirmauflösung */
/* large an up */
@media only screen and (min-width: 993px) {
  nav.grow {
    height: 109px;

    img.logo {
      height: 72px;
      padding-top: 0;
      vertical-align: middle;
    }

    ul {
      a {
        font-size: 1.5rem;
      }

      &.left {
        padding-top: 20px;
      }
    }
  }

  .bg1 {
    margin-top: 15px;
  }
}

/* 1600px and down */
@media only screen and (max-width: 1600px) {
  #contact .parallax img {
    min-width: 1600px;
    max-width: 1600px;
  }
}

/* 1200px and down */
@media only screen and (max-width: 1200px) {
  #contact {
    .parallax-container {
      min-height: 500px;

      .parallax img {
        min-width: 1200px;
        max-width: 1200px;
      }
    }
  }
}

/* med and up */
@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}

/* med and down */
@media only screen and (max-width: 992px) {
  .parallax img {
    min-height: initial;
    max-width: 1400px;
  }

  .parallax-container .section {
    position: absolute;

    // top: 10%;
    h1 {
      font-size: 3.5rem;
    }

    h5 {
      font-size: 1.5rem;
    }
  }

  #index-banner .section {
    top: 10%;
  }

  .bg1 {
    // margin-top: 15px;
  }
}

/* small and down */
@media only screen and (max-width: 600px) {
  #index-banner .section {
    top: 0;
  }

  .footer-copyright {
    height: auto !important;
  }
}

/* very small */
@media only screen and (max-width: 384px) {
  footer {
    img {
      &.oes {
        margin-top: 40px;
      }
    }
  }
}